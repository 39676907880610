<template>
  <b-form-group :id="`${name}-group`" :label="label" :label-for="`${name}-ID`">
    <multiselect
      id="`${name}-ID`"
      :placeholder="placeholder"
      :options="list_options"
      :label="valueLabel"
      :track-by="trackBy"
      select-label=""
      :searchable="true"
      :loading="isLoading"
      :close-on-select="true"
      :internal-search="false"
      :clear-on-select="false"
      @search-change="asyncFind"
      @close="removeOptions"
    >
      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <div>
            <div class="option__title">{{ props.option.name }}</div>
            <div class="option__small">
              {{ convertTimestampToDate(props.option.birthday) }} -
              {{ props.option.phoneNumber.replace('+84', 0) }}
            </div>
          </div>
          <b-button
            v-if="props.option.sharedProfile"
            class="btn btn-success"
            type="button"
            @click.stop="goToUserDashboard(props.option.id)"
          >
            Xem
          </b-button>
          <b-button
            v-else
            class="btn btn-outline-success"
            type="button"
            @click="showPopupConfirm(props.option)"
          >
            Yêu cầu
          </b-button>
        </div>
      </template>
      <template v-slot:noOptions>Không có kết quả</template>
      <template v-slot:noResult>
        <p>
          <span>Không có kết quả</span>
        </p></template
      >
    </multiselect>
  </b-form-group>
</template>
<script>
import debounce from 'lodash/debounce';
import Multiselect from 'vue-multiselect';
import { MODAL_STATUS } from '../../../../core/plugins/constants';
export default {
  name: 'InputFilter',
  components: {
    Multiselect,
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    valueLabel: {
      type: String,
      default: 'name',
    },
    trackBy: {
      type: String,
      default: 'id',
    },
  },
  data() {
    return {
      isLoading: false,
      list_options: [],
    };
  },
  watch: {
    options: {
      deep: true,
      handler: function (val) {
        this.list_options = val;
      },
    },
  },
  methods: {
    removeOptions() {
      this.list_options = [];
    },
    asyncFind: debounce(async function (query) {
      if (!query) return (this.list_options = []);
      try {
        this.isLoading = true;
        let keyword = query;

        if (query.startsWith('0')) {
          keyword = query.substring(1);
        }

        const { meta, data } = await this.$api.get('/UserDashboard/Patient', {
          params: {
            filterStr: keyword,
            page: 1,
            size: 100,
          },
        });
        if (!meta.success) return;

        this.list_options = [...data.items].map((el) => ({
          name: el.fullName,
          phoneNumber: el.phoneNumber,
          birthday: el.dateOfBirth,
          id: el.id,
          sharedProfile: el.sharedProfile,
        }));
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.isLoading = false;
      }
    }, 1000),
    showToastrMessage(message, type) {
      if (type === 'error') {
        return this.$toastr.e({
          title: 'Lỗi',
          msg: message,
        });
      }

      return this.$toastr.s({
        title: 'Thành công',
        msg: message,
      });
    },
    goToUserDashboard(id) {
      return this.$router
        .push({
          name: 'user_dashboard',
          params: {
            id,
          },
        })
        .catch(() => {});
    },
    showPopupConfirm(item) {
      this.$swal({
        title: 'Xác nhận',
        text: `Yêu cầu này sẽ được thông báo tới khách hàng, bạn có chắc chắn muốn gửi yêu cầu này`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Đồng ý',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            this.requestShareProfile(item);
          }
        }.bind(this),
      );
    },
    async requestShareProfile(item) {
      try {
        this.isLoading = true;
        const payload = {
          patientId: item.id,
          referalCodeType: MODAL_STATUS.NEW,
        };
        const { error, meta } = await this.$api.post(
          '/UserDashboard/Share-Profile',
          payload,
        );

        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }

        this.showToastrMessage(
          'Yêu cầu chia sử thông tin cá nhân thành công',
          'success',
        );
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.option {
  &__desc {
    display: grid;
    grid-template-columns: 1fr max-content;
    column-gap: 12px;
  }

  &__title {
    font-size: 13px;
    line-height: 20px;
    color: #050505;
  }

  &__small {
    font-size: 12px;
    line-height: 16px;
    color: #888c9f;
  }
}
</style>
